import React from "react";
import MetaTags from "../common/Meta";

const PrivacyPolicy = () => {
  return (
    <div>
      <MetaTags
        title="Privecy Policy | Car Ads"
        description="Car Ads respects the privacy of our users. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website."
        keywords="Car advertising, Car ads, Car wrap advertising, Vehicle advertising, Digital advertising on cars, Car advertisements, Car branding, Ooh advertising, Out of home advertising, ooh ads, Ooh billboard, Ooh agency, Ooh companies, Car advertising agency, Car advertising companies."
      />
      <div className="container">
        <div className="col-md-6 brands" style={{ marginTop: 30 }}>
          <h1>
            <span>Privacy Policy</span>
          </h1>
        </div>
        <div className="row d-flex align-items-center mb-4 pb-4 privecy-policy-content">
          <div className="col-md-12 p-4">
            <p>
              Your privacy is important to CarAds and we are committed to respecting it.This privacy policy applies to all information collected through our Website and mobile application.If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at info@carads.com.bd.
            </p>
            <h3>WHAT INFORMATION DO WE COLLECT?</h3>
            <p>
              We collect your first and last name, email address, postal address, phone number, and other similar contact data.<br /><br />
              We collect passwords, password hints, and similar security information used for authentication and account access.<br /><br />
              All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.
            </p>
            <h3>HOW DO WE USE YOUR INFORMATION?</h3>
            <p><b>To send you marketing and promotional communications</b>. We and/or our third party marketing partners may use the personal information you send to us for our marketing purposes.<br /><br />
              <b>To send administrative information to you.</b> We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.<br /><br />
              <b>Administer prize draws and competitions.</b> We may use your information to administer prize draws and competitions when you elect to participate in our competitions. <br /><br />
              <b>Request Feedback.</b> We may use your information to request feedback and to contact you about your use of our Sites or Apps.
              </p>
            <h3>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h3> 
            <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information.</p> 
            <h3>DO WE MAKE UPDATES TO THIS POLICY?</h3> 
            <p>We may update this privacy policy from time to time. The updated version will be indicated by an updated &quot;Revised&quot; date and the updated version will be effective as soon as it is accessible.</p> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
