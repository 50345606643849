import React from "react";
import car from "../../assets/images/ffff.png";
import monitor from "../../assets/images/mac.png";
import gplay from "../../assets/images/play.png";
import MetaTags from "../common/Meta";
import AppStoreImg from "../../assets/images/app-store.png";

const Brands = () => {
  return (
    <div>
      <MetaTags
        title="Brands & Agencies | Car Ads"
        description="Car wrap marketing is a premium OOH solution that delivers high impact branding and awareness for your business. Talk to one of our experts today!"
        keywords="Car advertising, Car ads, Car wrap advertising, Vehicle advertising, Digital advertising on cars, Car advertisements, Car branding, Ooh advertising, Out of home advertising, ooh ads, Ooh billboard, Ooh agency, Ooh companies, Car advertising agency, Car advertising companies."
      />
      <div className="container ">
        <div className="row d-flex align-items-center bg-padding">
          <div className="col-md-6 pdt-30 brands">
            <h1 className="brandpage">
              BRAND <span>ADVERTISEMET</span>
            </h1>
            <p>
              {" "}
              <small>
                The meteoric rise of Uber,Pathao, and Obhai others has created a
                new class of vehicles that garner high exposure in front of your
                target audience. Creative, memorable, and technologically
                advanced, CAR ADS is redefining out-of-home.
              </small>
            </p>
            <a
              type="button"
              href="https://brand.carads.com.bd/#/login"
              target="_blank"
              className="btn driver"
            >
              Brands Login
            </a>
            {/* <button type="button" className="btn driver">
              Brands Registration
            </button> */}
          </div>
          <div className="text-right col-md-6 car-head pdt-60">
            <img src={car} alt="" />
          </div>
        </div>

        <div className="row mt-80">
          <div className="col-md-7">
            <img className="img-fluid" src={monitor} alt="" />
          </div>
          <div className="col-md-5 carVar">
            <h2>
              CARADS DRIVER AS <br /> <span>ADVERTISING PLATFORM</span>
            </h2>
            <p>
              We don’t just wrap vehicles. Our Mobile App Based Ad Tech Platform
              will make you the controller of your campaign.
            </p>
            <p className="mt-3">
              With our user friendly Mobile App and Web App Dashboard you can
              easily track your wrapped vehicle’s real time route,distance,
              analytics with our revolutionary Impression Count Algorithm.
            </p>
            <p className="mt-3">
              We take the responsibility on behalf of Brands to advertise their
              products or services more effectively and efficiently than any
              other marketing platforms in Bangladesh by using tech.
            </p>
            <a
              href="https://play.google.com/store/apps/details?id=com.carads.brand"
              target="_blank"
              rel="noreferrer"
            >
              <img src={gplay} alt="" />{" "}
            </a>
            <a href="#">
              <img
                src={AppStoreImg}
                alt="App store"
                style={{ maxWidth: 180 }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brands;
