import React from "react";
import "./Contact.css";
import MetaTags from "../common/Meta";

const ContactUs = () => {
  return (
    <div>
      <MetaTags
        title="Contact us | Car Ads"
        description="Brands, drivers & consumers are easy to contact car ads. Car Ads office Address Niketon, Gulshan 1, Dhaka. Hotline: +88 019 9999 1022  ;  +88 096 7808 0999."
        keywords="Car advertising, Car ads, Car wrap advertising, Vehicle advertising, Digital advertising on cars, Car advertisements, Car branding, Ooh advertising, Out of home advertising, ooh ads, Ooh billboard, Ooh agency, Ooh companies, Car advertising agency, Car advertising companies."
      />
      <div className="contact-us-header">
          <h1>
            <span>CONTACT US</span>
          </h1>
      </div>
      <div className="container">
        <div className="col-lg-12 col-md-12 brands" style={{ marginTop: 30 }}>
          <div className="row">
              <div className="contact-us-card1 col-lg-4 col-md-4 col-sm-6 col-12">
                  
              </div>
              <div className="contact-us-card2 col-lg-4 col-md-4 col-sm-6 col-12">
                 
              </div>
              <div className="contact-us-card3 col-lg-4 col-md-4 col-sm-6 col-12">
                  
              </div>
              <div className="contact-us-card4 col-lg-4 col-md-4 col-sm-6 col-12">
                  
              </div>
              <div className="contact-us-card5 col-lg-4 col-md-4 col-sm-6 col-12">
                 
              </div>
              <div className="contact-us-card6 col-lg-4 col-md-4 col-sm-6 col-12">
                 
              </div>
          </div>
        </div>
        <br /><br /><br /><br /><br /><br />
        <div className="google-map-code">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d967.0635264052318!2d90.41118279370595!3d23.777824193326587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c77f006850f7%3A0xdb569915786ecf10!2zQ2FyIEFkcyB8IOCml-CmvuCmoeCmvOCmvyDgpqzgpr_gppzgp43gpp7gpr7gpqrgpqg!5e0!3m2!1sen!2sbd!4v1642932225414!5m2!1sen!2sbd" width="1100" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </div>
        <div className="row d-flex mb-4 pb-4">
          {/* <div className="col-md-6 brands">
            <div className="card">
              <div className="card-body"> 
                <div className="col-md-6">
                  <p>info@carads.com.bd</p>
                  <div className="mt-3 d-flex">
                    <p className="company_location">
                      +88 02 4108 0999
                      <br />
                      +88 096 7808 0999
                      <br />
                      +88 019 9999 1022
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/*<div className="col-md-10 car-head pdt-60 brands mx-auto">
            <div className="card">
              <div className="card-body d-flex align-items-center ">
                <div className="col-md-6 contactUsBorder">
                  <p>info@carads.com.bd</p>
                  <div className="mt-3 d-flex">
                    <p className="company_location">
                      +88 02 4108 0999
                      <br />
                      +88 096 7808 0999
                      <br />
                      +88 019 9999 1022
                    </p>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="company_location">
                    <p className="font-weight-bold mb-2">
                      Corporate Head Office:
                    </p>
                    <p>
                      House-82/1, Road-02, Block-A, Niketon, Gulshan-01,
                      Dhaka-1212.{" "}
                    </p>

                    <div className="mt-2">
                      <p className="font-weight-bold mb-2">
                        Corporate Drivers Hub:
                      </p>
                      <p>H#14,R#14, Block-D, Mirpur-06, Dhaka-1216.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
