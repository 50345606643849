/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory } from "react-router";
import logo from "../../assets/images/logo.png";

import "./Navbar.css";

const Navbar = () => {
  const history = useHistory();

  return (
    <div>
      <nav className="navbar navbar-light navbar-expand-md navigation-clean sticky-top">
        <div className="container">
          <img onClick={() => history.push("/")} src={logo} alt="" />
          <button
            data-toggle="collapse"
            className="navbar-toggler"
            data-target="#navcol-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navcol-1">
            <ul className="ml-auto nav navbar-nav">
              <li className="nav-item" role="presentation">
                <a className="nav-link " href="/home">
                  {/* <button id="grad" type="button" className="btn">
                    Home
                  </button> */}
                  Home
                </a>
              </li>

              <li className="nav-item vertical_bar" role="presentation">
                <a className="nav-link" id="nav_ver" href="/brands">
                  For Brands & Agencies
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a className="nav-link" href="/driver">
                  For Drivers
                </a>
              </li>
              {/* <li className="nav-item" role="presentation">
                <a className="nav-link" href="/">
                  Blogs
                </a>
              </li> */}
              <li className="nav-item" role="presentation">
                <a className="nav-link" href="/about-us">
                  About Us
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a className="nav-link" href="/contact-us">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
