import React from "react";
import car2 from "../../assets/images/car2.png";
import MetaTags from "../common/Meta";
import gplay from "../../assets/images/play.png";
import AppStoreImg from "../../assets/images/app-store.png";

const Driver = () => {
  return (
    <div className="container">
      <MetaTags
        title="For Drivers |Car Ads"
        description="Car wrap ads are a great way to earn money - For Drivers। Drivers can earn per month Extra Cash by wrapping sticker on their vehicle -  free to join"
        keywords="Car advertising, Car ads, Car wrap advertising, Vehicle advertising, Digital advertising on cars, Car advertisements, Car branding, Ooh advertising, Out of home advertising, ooh ads, Ooh billboard, Ooh agency, Ooh companies, Car advertising agency, Car advertising companies."
      />
      <div className="row d-flex align-items-center bg-padding">
        <div className="col-md-6 pdt-30 brands">
          <h1 className="brandpage">
            Car Ads for Driver <br /> <span>Drive & Earn</span>
          </h1>
          <p>
            {" "}
            <small>
              Join the thousands of drivers who are earning monthly income with
              us by placing an advertisement on your car.
            </small>
          </p>
          <div className="d-flex mt-4">
            <a
              href="https://play.google.com/store/apps/details?id=com.carads.driver"
              target="_blank"
              className="mr-2"
              rel="noreferrer">
              <img src={gplay} className="playstore" alt="Google Play" style={{ maxWidth: 180 }} />
            </a>
            <a href="#" type="button">
              <img
                src={AppStoreImg}
                alt="App store"
                style={{ maxWidth: 180 }}
                className="appstore"
              />
            </a>
          </div>

          {/* <button type="button" className="btn driver">
            Driver Registration
          </button> */}
        </div>
        <div className="text-right col-md-6 car-head pdt-60">
          <img src={car2} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Driver;