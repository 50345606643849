import React from "react";
import MetaTags from "../common/Meta";

const TermsConditions = () => {
  return (
    <div>
      <MetaTags
        title="Terms and Conditions | Car Ads"
        description="You can read our terms and conditions here....The advertisers are interested in car wraps then come to an agreement about the details of the car wrap project."
        keywords="Car advertising, Car ads, Car wrap advertising, Vehicle advertising, Digital advertising on cars, Car advertisements, Car branding, Ooh advertising, Out of home advertising, ooh ads, Ooh billboard, Ooh agency, Ooh companies, Car advertising agency, Car advertising companies"
      />
      <div className="container">
        <div className="col-md-6 brands" style={{ marginTop: 30 }}>
          <h1>
            <span>Terms & conditions</span>
          </h1>
        </div>
        <div className="row d-flex align-items-center mb-4 pb-4 pageContest">
          <div className="col-md-12 p-4">
            <h4>
              CHECK OUT OUR TERMS & CONDITIONS AND SEE HOW YOUR PRIVACY IS
              PROTECTED WITH US.
            </h4>
            <p>
              This privacy policy has been compiled to better serve those who
              are concerned with how their ‘Personally identifiable information’
              (PII) is being used online. PII, as used in BANGLADESH privacy law
              and information security, is information that can be used on its
              own or with other information to identify, contact, or locate a
              single person, or to identify an individual in context. Please
              read our privacy policy carefully to get a clear understanding of
              how we collect, use, protect or otherwise handle your Personally
              Identifiable Information in accordance with our website.
            </p>
            <p>
              We strive to offer the best service possible – to offer commuters
              and drivers the opportunity to earn extra income by wrapping their
              vehicle in eye-catching art and commercial messaging. For purposes
              of this Terms & Conditions (“Conditions” or “Terms & Conditions”),
              the terms “we,” “us,” “our” and “Car Ads” refer to E-driver, Ltd.,
              and “you” and “your” refers to you, as a user of the Car Ads
              service. The Terms & Conditions below governs your Car Ads account
              and any information you provide on this site.
            </p>
            <p>
              This Terms & Conditions covers both our “online” (e.g., web and
              mobile services, including any web sites operated by us such as
              www,carads.com.bd, mobile applications, however accessed and/or
              used, whether via personal computers, mobile devices or otherwise)
              and “offline” (e.g., collection of data through mailings,
              telephone, or in person) activities owned, operated, provided, or
              made available by Car Ads. Our “online” and “offline” activities
              are collectively referenced as the “Services.” This Privacy Policy
              also applies to your use of interactive features or downloads
              that:
            </p>
            <p>
              1. We own or control.
              <br />
              2. Are available through the Services; or <br />
              3. Interact with the Services and post or incorporate these Terms
              & Conditions.
            </p>
            <h4>
              What personal information do we collect from the people that visit
              our blog, website or app?
            </h4>
            <p>
              When ordering or registering on our site, as appropriate, you may
              be asked to enter your name, email address, mailing address, phone
              number or other details to help you with your experience.
            </p>
            <h4>When do we collect information?</h4>
            <p>
              We collect information from you when you register on our site,
              place an order, subscribe to a newsletter, respond to a survey,
              fill out a form or enter information on our site.
            </p>
            <h4>How do we use your information?</h4>
            <p>
              We may use the information we collect from you when you register,
              make a purchase, sign up for our newsletter, respond to a survey
              or marketing communication, surf the website, or use certain other
              site features in the following ways:
            </p>
            <p>
              <ul>
                <li>
                  To personalize user’s experience and to allow us to deliver
                  the type of content and product offerings in which you are
                  most interested.
                </li>
                <li>To improve our website in order to better serve you.</li>
                <li>
                  To administer a contest, promotion, survey or other site
                  feature.
                </li>
                <li>
                  To send periodic emails regarding your order or other products
                  and services.
                </li>
              </ul>
            </p>
            <h4>How do we protect visitor information?</h4>
            <p>
              <ul>
                <li>
                  We do not use vulnerability scanning and/or scanning to PCI
                  standards.
                </li>
                <li>We use regular Malware Scanning.</li>
                <li>We use an SSL certificate</li>
              </ul>
            </p>
            <h4>Do we use ‘cookies’?</h4>
            <p>
              Yes. Cookies are small files that a site or its service provider
              transfers to your computer’s hard drive through your Web browser
              (if you allow) that enables the site’s or service provider’s
              systems to recognize your browser and capture and remember certain
              information. For instance, we use cookies to help us remember and
              process the items in your shopping cart. They are also used to
              help us understand your preferences based on previous or current
              site activity, which enables us to provide you with improved
              services. We also use cookies to help us compile aggregate data
              about site traffic and site interaction so that we can offer
              better site experiences and tools in the future.
            </p>
            <h4>We use cookies to:</h4>
            <p>
              <ul>
                <li>
                  Understand and save user’s preferences for future visits.
                </li>
                <li>Keep track of advertisements.</li>
                <li>
                  Compile aggregate data about site traffic and site
                  interactions in order to offer better site experiences and
                  tools in the future. We may also use trusted third party
                  services that track this information on our behalf.
                </li>
                <li>
                  You can choose to have your computer warn you each time a
                  cookie is being sent, or you can choose to turn off all
                  cookies. You do this through your browser (like Internet
                  Explorer) settings. Each browser is a little different, so
                  look at your browser’s Help menu to learn the correct way to
                  modify your cookies.
                </li>
              </ul>
            </p>
            <p>
              If you disable cookies off, some features will be disabled It
              won’t affect the users’ experience that makes your site experience
              more efficient and some of our services will not function
              properly.
            </p>
            <p className="text-center">However, you can still place orders.</p>
            <h4>Third Party Disclosure</h4>
            <p>
              We do not sell, trade, or otherwise transfer to outside parties
              your personally identifiable information unless we provide you
              with advance notice. This does not include website hosting
              partners and other parties who assist us in operating our website,
              conducting our business, or servicing you, so long as those
              parties agree to keep this information confidential. We may also
              release your information when we believe release is appropriate to
              comply with the law, enforce our site policies, or protect ours or
              others’ rights, property, or safety. However, non-personally
              identifiable visitor information may be provided to other parties
              for marketing, advertising, or other uses.
            </p>
            <h4>Third party links</h4>
            <p>
              We do not include or offer third party products or services on our
              website.
            </p>
            <h4>How does our site handle not track signals?</h4>
            <p>
              We don’t honor, do not track signals and do not track, plant
              cookies, or use advertising when a Do Not Track (DNT) browser
              mechanism is in place. We don’t honor them because:
            </p>
            <p>
              Third party systems, such as Google Analytics, may or may not
              honor DO Not Track signals without notification
            </p>
            <h4>COPPA (Children Online Privacy Protection Act)</h4>
            <p>
              When it comes to the collection of personal information from
              children under 13, the Children’s Online Privacy Protection Act
              (COPPA) puts parents in control. The Federal Trade Commission, the
              nation’s consumer protection agency, enforces the COPPA Rule,
              which spells out what operators of websites and online services
              must do to protect children’s privacy and safety online.
            </p>
            <p>We do not specifically market to children under 13.</p>
            <h4>Driver Information</h4>
            <p>
              In order to operate the “<b> Car Ads </b>“ service and to provide
              our Services, including, but not limited to, matching commuters
              and drivers with advertisers, we must ask you to provide nonpublic
              personal information about yourself, such as your name, email
              address and mobile number (“Personal Information”). We may collect
              this information through various forms and in various places
              through the Services, including account registration forms,
              “contact us forms”, or when you otherwise interact with us.{" "}
            </p>
            <p>
              When you sign up to use the Services, you create a user profile.
              The current required data fields are:
            </p>
            <p>
              <ul>
                <li>Name</li>
                <li>Phone Number</li>
                <li>Address</li>
                <li>Address</li>
                <li>National ID No</li>
                <li>Driver License (Motor Vehicle Record)</li>
                <li>Vehicle Information</li>
              </ul>
            </p>
            <p>
              <b>Geolocation.</b> We collect your unique user identifier and
              your location through GPS,Mobile Networks,WiFi, or wireless
              network triangulation in order to obtain your location for the
              purposes of providing our Service. We collect the location to
              provide your location information for campaign purposes. While you
              are logged in to your account, we will continue to collect your
              location information in order to provide the Service (for example,
              to match you with a campaign).We maintain location information
              only so long as it is reasonable to provide the Service and then
              delete location data tied to your personal information. We may
              maintain de-identified location data for a longer period of time
              in order to analyze aggregate trends and metrics. If you want to
              opt-out of the collection of your location data, please adjust
              your settings in your mobile device to limit the app’s access to
              your location data.
            </p>
            <p>
              <b>Driving Event Data.</b> We collect, and may use a third party
              to collect, sensory and motion data from your smartphone or mobile
              device, including information from the gyroscope, accelerometer,
              compass, and Bluetooth, in order to calculate and detect driving
              events such as speeding, hard braking, crash detection, and other
              events. We maintain sensory and motion data as long as it is
              reasonable to provide the Service and then delete or anonymize
              this data. If you want to opt-out of the collection of your
              sensory and motion, please adjust your settings in your mobile
              device to limit the app’s access to your sensory and motion data.
            </p>
            </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
