import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import "../src/assets/css/Style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from "./components/footer/Footer";
import HomePage from "./components/hompage/HomePage";
import Brands from "./components/brands/Brands";
import Blogs from "./components/blogs/Blog";
import Driver from "./components/driver/Driver";
import ContactUs from "./components/contactUs/ContactUs";
import AboutUs from "./components/aboutUs/AboutUs";
import TermsConditions from "./components/termsConditions/TermsConditions";
import Clients from "./components/clitent/Client";
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy";
import Drivewithus from "./components/withus/Drivewithus";
import Adwithus from "./components/withus/Adwithus";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />

        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/home">
            <HomePage />
          </Route>
          <Route path="/blogs">
            <Blogs />
          </Route>
          <Route path="/brands">
            <Brands />
          </Route>
          <Route path="/driver">
            <Driver />
          </Route>

          <Route path="/contact-us">
            <ContactUs />
          </Route>
          <Route path="/about-us">
            <AboutUs />
          </Route>
          <Route path="/terms-conditions">
            <TermsConditions />
          </Route>
          <Route path="/clients">
            <Clients />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/drive-with-us">
            <Drivewithus />
          </Route>
          <Route path="/advertise-with-us">
            <Adwithus />
          </Route>
        </Switch>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
