import React from "react";
import "./Footer.css";
import phone from "../../assets/images/footer/phone-call.png";
import envelap from "../../assets/images/footer/envelope.png";
import home from "../../assets/images/footer/home.png";
import logo from "../../assets/images/Ca-logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="footer-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-5 intro">
            <img src={logo} alt="" id="int" />
            <p>
            <br></br>
              <strong>Car Ads</strong> is an OOH Ad-Tech advertising technology startup in
              Bangladesh that connects brands, drivers & consumers to create
              measurable outdoor and digital advertising. Car advertising decals
              will help you attract new clients. Car Wrap Ads, One of the cheapest costs
              per impression of almost any outdoor advertising.
              Your premium Out Of Home (OOH) solution. Get your brand moving in the real world
            </p>
            <ul className="social-media-icon">
              <li className="icon-list"><a href="https://www.facebook.com/caradsbd" target="_blank"><FontAwesomeIcon icon={faFacebook} color="white" /></a></li>
              <li className="icon-list"><a href="https://www.instagram.com/caradsbd/" target="_blank"><FontAwesomeIcon icon={faInstagram} color="white" /></a></li>
              <li className="icon-list"><a href="https://twitter.com/CarAdsbd" target="_blank"><FontAwesomeIcon icon={faTwitter} color="white" /></a></li>
              <li className="icon-list"><a href="https://www.linkedin.com/in/carads/" target="_blank"><FontAwesomeIcon icon={faLinkedin} color="white" /></a></li>
              <li className="icon-list"><a href="/driver" target="_blank"><FontAwesomeIcon icon={faYoutube} color="white" /></a></li>
            </ul>
            {/* <button type="button" className="btn footer_driver">
              Talk to the Team
            </button>
            <button type="button" className="btn footer_adviser">
              Become a Driver
            </button> */}
          </div>

          <div className="col-md-2 links">
            <h4>SERVICES</h4>
            <p>
              <a href="/drive-with-us">Drive with us</a>
            </p>
            <p>
              <a href="/advertise-with-us">Advertise with us</a>
            </p>
            <p>
              <a href="/clients">Clients</a>
            </p>
            <p>
              <a href="/blogs">Blog</a>
            </p>
          </div>

          <div className="col-md-2 links">
            <h4>USEFUL LINKS</h4>

            <p>
              <a href="/about-us">About Us</a>
            </p>
            <p>
              <a href="/contact-us">Contact Us</a>
            </p>
            <p>
              <a href="/terms-conditions">Terms & Conditions</a>
            </p>
            <p>
              <a href="/privacy-policy">Privacy Policy</a>
            </p>
          </div>
          <div className="col-md-3 links">
            <h4>CONTACT</h4>
            <p>
              <img src={envelap} alt="" />
              &nbsp;&nbsp; info@carads.com.bd
            </p>
            <div className="mt-3 d-flex">
              <p>
                {" "}
                <img src={phone} alt="" />
              </p>
              <p className="company_location">
                +88 02 4108 0999; <br />
                +88 096 7808 0999;
                <br />
                +88 019 9999 1022
              </p>
            </div>
            <div>
              <div className="mt-3 d-flex">
                <p>
                  {" "}
                  <img src={home} alt="" />
                </p>
                <div className="company_location">
                  <p>
                    {" "}
                    <span> Corporate Head Office:</span> <br />
                    House-82/1, Road-02, Block-A, Niketon, Gulshan-01,
                    Dhaka-1212.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </footer>
  );
};

export default Footer;
