import React from "react";
import carHero from "../../assets/images/HomePage/CarHero.png";
import whatdo from "../../assets/images/HomePage/whatDo.png";
import whatdo2 from "../../assets/images/HomePage/whatdo2.png";
import car from "../../assets/images/HomePage/car111.png";
import primary from "../../assets/images/HomePage/primary1.png";
import partial from "../../assets/images/HomePage/partial1.png";
import professional from "../../assets/images/HomePage/professional1.png";
import premium from "../../assets/images/HomePage/premium1.png";
import markdown from "../../assets/images/download.png";
import MetaTags from "../common/Meta";
import { useHistory } from "react-router-dom";

const HomePage = () => {
  const history = useHistory();

  return (
    <div>
      <MetaTags
        title="Car Ads | Moving Billboards"
        description="Car Ads is an OOH Ad-Tech advertising technology start-up in Bangladesh that connects brands, drivers & consumers to create measurable outdoor advertising."
        keywords="Car advertising, Car ads, Car wrap advertising, Vehicle advertising, Digital advertising on cars, Car advertisements, Car branding, Ooh advertising, Out of home advertising, ooh ads, Ooh billboard, Ooh agency, Ooh companies, Car advertising agency, Car advertising companies."
      />
      <div className="bg-img curve_p">
        <div className="container">
          <div className="row bg-padding">
            <div className="col-md-6 pdt-30 headpara">
              <p>
                Welcome to{" "}
                <span className="brand-color">
                  <strong>CarAds</strong>
                </span>
              </p>
              <h1>
                Moving Billboards{" "}
                <br></br>
                <span className="fastest-color">fastest Branding</span>
              </h1>
              <h4>
               Car Ads is an OOH Ad-Tech advertising technology startup in
              Bangladesh that connects brands, drivers & consumers to create
              measurable outdoor and digital advertising. Car advertising decals
              will help you attract new clients. Car Ads is a professional car advertise provider company. This Platform is for promoting your brand and company.
              </h4>
            </div>
            <div className="col-md-6 car-head pdt-60">
              <img src={carHero} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="container section2">
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-center">What do we do?</h1>
            <h5 className="pt-2 text-center">Our story, miniaturised</h5>
          </div>
        </div>
        <div className="row m-100 d-flex align-items-center ">
          <div className="col-sm-6">
            <h2>For Advertisers</h2>
            <h4 className="mb-4">
              Add speed to your outdoor advertising, turn heads and track
              everything.
            </h4>
            <p>
              We take the responsibility on behalf of Brands to advertise their
              products or services more effectively and efficiently than any
              other marketing platforms in Bangladesh by using tech.
            </p>
            <hr />

            <div className="markdown">
              <p>
                {" "}
                <img src={markdown} alt="" />
                AFFORDABLE ADVERTISING
              </p>
              <p>
                {" "}
                <img src={markdown} alt="" /> EXCLUSIVE ONLINE DASHBOARD
              </p>
              <p>
                {" "}
                <img src={markdown} alt="" />
                REAL TIME MONITOR YOUR CAMPAIGNS ONLINE
              </p>
              <p>
                {" "}
                <img src={markdown} alt="" />
                TRACK ONLINE WITH ONE TIME MULTIPLE DRIVERS
              </p>
              <p>
                {" "}
                <img src={markdown} alt="" />
                WHEN DRIVERS ONLINE EVERY TIME SEE TRACKING LOCATION
              </p>
              <p>
                {" "}
                <img src={markdown} alt="" />
                GEOGRAPHICALLY SEEN EVERY DRIVERS TOTAL DATA INFORMATIONS DAY,
                WEEK OR MONTHLY INDIVIDUAL IN OUR BRAND DASHBOARD
              </p>
            </div>

            <button
              type="button"
              className="mt-3 btn_learn"
              onClick={() => history.push("/brands")}
            >
              Learn More
            </button>
          </div>
          <div className="text-right col-sm-6 forAdsvertiser">
            <img className="img-fluid" src={whatdo} alt="" />
          </div>
        </div>
        <div className="row d-flex align-items-center ">
          <div className="col-sm-6 forDrivers">
            <img className="img-fluid" src={whatdo2} alt="" />
          </div>
          <div className="col-sm-6">
            <h2>For Drivers</h2>
            <h4 className="mb-4">
              Brands pay to advertise affordable cars. Plus, we’ll give Brands
              tons of perks and discounts in our app.
            </h4>
            <hr />

            <div className="markdown">
              <p>
                {" "}
                <img src={markdown} alt="" /> WE WRAP YOUR CAR AND THEN PAY YOU
                EVERY MONTH THROUGH DIRECT CASH,MOBILE BANKING OR BANK
              </p>
              <p>
                {" "}
                <img src={markdown} alt="" /> WE TAKE THE AD OFF AT THE END OF
                THE CAMPAIGN
              </p>
              <p>
                {" "}
                <img src={markdown} alt="" />
                ADHESIVE VINYL PAPER STICKER TO PROTECT YOUR CAR COLOR
              </p>
              <p>
                {" "}
                <img src={markdown} alt="" />
                LEGAL PERMITS OF GOVERNMENTS TO WRAPPING CAR WITH STICKER
              </p>
            </div>

            <button
              type="button"
              className="mt-3 btn_learn"
              onClick={() => history.push("/driver")}
            >
              Learn More
            </button>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-4 carCurve-p">
        <div className="container section-3">
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-md-4">
              <h5 className="icon"> Massive Audience</h5>

              <p>Time spent outside the home is increasing.</p>
              <p>
                OOH remains a mass reach medium, reaching at least half of all
                consumers in most markets and as much as 90% in some
              </p>

              <h5 className="mt-4 icon"> Unique & Impressive View</h5>

              <p>
                Measurement incorporates the latest technologies like GPS,
                location data, WiFi and eye tracking, which ensures the optimum
                accuracy of audience data.
              </p>
              <p>
                Unlike most other media which provide only viewable impressions,
                the best OOH audience measurement delivers actual viewed
                impressions
              </p>

              <h5 className="icon"> Easier To Notice</h5>
              <p>
                OOH plays a natural and accepted role in the urban landscape and
                therefore it is part of consumers’ daily lives.
              </p>
              <p>
                Its physical presence in the real world means it cannot be
                blocked like online ads can.
              </p>
            </div>
            <div className="text-center col-md-4">
              {/* <img class="img-fluid" src="/assets/img/car33.png" alt="phone image"> */}
              <img className="img-fluid" src={car} alt="" />
            </div>

            <div className="col-md-4 ">
              <h5 className="icon"> Cost Effective</h5>
              <p>
                Research studies have repeatedly shown that OOH significantly
                lowers the cost of advertising. To reach 1,000 people, online
                can cost up to $17.50 per thousand impressions, magazines can
                cost up to $21.00 per thousand, and spot broadcast or cable can
                cost $22.00 - $25.00 per thousand. At $3.38 - $8.65 per thousand
                impressions, OOH delivers the same audience at significantly
                lower cost.
              </p>
              {/* <p>
                A recent global econometrics study proved that OOH delivers a
                high return on investment. For every $1 spent on OOH
                advertising, $2.80 in sales result.
              </p> */}
              <h5 className="mt-4 icon"> Fastest & Easiest Branding</h5>
              {/* <p>
                OOH has always excelled at campaign creativity whether within
                formats, environments or creative concepts.
              </p> */}
              <p>
                New technologies in DOOH with connectivity offer new
                opportunities for engagement and interactivity including
                audience-driven mobile integrations and live feeds of events.
              </p>
              <h5 className="mt-4 icon"> Digital Branding</h5>
              <p>
                DOOH is the second fastest growing medium and it is predicted to
                account for over 30% of OOH revenue in some mature markets eg UK
                and Australia.
              </p>
              {/* <p>
                DOOH global share of OOH revenue is predicted to grow to 24% by
                2021
              </p>
              <p>
                Digital OOH (DOOH) allows more advertisers to benefit from
                premium sites, which increases the revenue that each screen
                generates.
              </p> */}
            </div>
          </div>
        </div>
      </div>

      {/*  */}
      <div className="container pt-4 section-4">
        <h4 className="text-center">
          Grow Your Company Promotion With Our AD Service & Promote Ideas
        </h4>
        <p className="text-center">
          Car Wrap Ads. Your premium OOH solution. Get your brand moving in the
          real world.Car advertising decals will help you attract new clients..
          One of the cheapest costs per impression of almost any outdoor
          advertising.
        </p>
      </div>

      <div className="margin-50 row d-flex justify-content-center">
        <div className="container col-sm-6 col-6 max-40 text-right">
          {/* <div className="row ">
                            <div className="col-lg-12">
                                <div className="first-bar progress-skill-bar">
                                    <h5>Primary</h5><span>25%</span>

                                    <div className="filled-bar"></div>
                                    <div className="full-bar"></div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="second-bar progress-skill-bar">
                                    <h6>Partial <span>50%</span></h6>

                                    <div className="filled-bar"></div>
                                    <div className="full-bar"></div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="third-bar progress-skill-bar">
                                    <h6>Professional</h6>
                                    <span>70%</span>
                                    <div className="filled-bar"></div>
                                    <div className="full-bar"></div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="fourth-bar progress-skill-bar">
                                    <h6>Premium</h6>
                                    <span>98%</span>
                                    <div className="filled-bar"></div>
                                    <div className="full-bar"></div>
                                </div>
                            </div>
                        </div> */}
          <div className="first-bar progress-skill-bar margin-30">
            <h6>
              Primary <span>25%</span>
            </h6>

            <div className="filled-bar"></div>
            <div className="full-bar"></div>
          </div>
        </div>
        <div className="text-right primary_img col-sm-6 col-6">
          {/* <img class="img-fluid" src="/assets/img/CA-packages.png" alt=""> */}
          <img className="img-fluid" src={primary} alt="" />
        </div>
      </div>

      <div className="row d-flex margin_neg">
        <div className="col-sm-6 col-6">
          <img className="img-fluid" src={partial} alt="" />
        </div>
        <div className="container col-sm-6 col-6 max-40">
          <div className="second-bar progress-skill-bar margin-30">
            <h6>
              Partial <span>50%</span>
            </h6>

            <div className="filled-bar"></div>
            <div className="full-bar"></div>
          </div>
        </div>
      </div>

      <div className="row d-flex margin_neg">
        <div className="container col-sm-6 col-6 max-40">
          <div className="third-bar progress-skill-bar margin-30">
            <h6>
              Professional <span>70%</span>
            </h6>

            <div className="filled-bar"></div>
            <div className="full-bar"></div>
          </div>
        </div>
        <div className="text-right col-sm-6 col-6">
          <div className="third-bar progress-skill-bar">
            <img className="img-fluid" src={professional} alt="" />
          </div>
        </div>
      </div>

      <div className="pb-3 mb-5 row d-flex margin_neg">
        <div className="col-sm-6 col-6">
          <img className="img-fluid" src={premium} alt="" />
        </div>
        <div className="container col-sm-6 col-6 max-40 ">
          <div className="fourth-bar progress-skill-bar margin-30">
            <h6>
              Premium <span>98%</span>
            </h6>

            <div className="filled-bar"></div>
            <div className="full-bar"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
