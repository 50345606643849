import React from "react";
import MetaTags from "../common/Meta";
import ebangla from "../../assets/images/ebanglamart.png";
import edriver from "../../assets/images/edriver.png";
import echemi from "../../assets/images/Echemi.png";
import nexgen from "../../assets/images/Nexgen-Chemi.png";
import holsel from "../../assets/images/Holsel.png";
import HA from "../../assets/images/HA-Tex.png";
import Flybee from "../../assets/images/flybee.png";

const Clients = () => {
  return (
    <div>
    <MetaTags
        title="Clients | Car Ads"
        description="Car wrap marketing is a premium OOH solution that delivers high impact branding and awareness for your business. Talk to one of our experts today!"
        keywords="Car advertising, Car ads, Car wrap advertising, Vehicle advertising, Digital advertising on cars, Car advertisements, Car branding, Ooh advertising, Out of home advertising, ooh ads, Ooh billboard, Ooh agency, Ooh companies, Car advertising agency, Car advertising companies."
      />
      <div className="container">
        <div className="col-md-6 brands" style={{ marginTop: 30 }}>
          <h1>
            <span>Our Clients</span>
          </h1>
        </div>
        <div className="row d-flex align-items-center mb-4 pb-4 aboutUs">
          <div className="col-md-4 col-lg-4 col-6 col-sm-6 p-4">
            <img src={ebangla} alt="" id="client-logo" />
          </div>
          <div className="col-md-4 col-lg-4 col-6 col-sm-6 p-4">
            <img src={edriver} alt="" id="client-logo" />
          </div>
          <div className="col-md-4 col-lg-4 col-6 col-sm-6 p-4">
            <img src={echemi} alt="" id="client-logo" />
          </div>
          <div className="col-md-4 col-lg-4 col-6 col-sm-6 p-4">
            <img src={nexgen} alt="" id="client-logo" />
          </div>
          <div className="col-md-4 col-lg-4 col-6 col-sm-6 p-4">
            <img src={holsel} alt="" id="client-logo" />
          </div>
          <div className="col-md-4 col-lg-4 col-6 col-sm-6 p-4">
            <img src={HA} alt="" id="client-logo" />
          </div>
          <div className="col-md-4 col-lg-4 col-6 col-sm-6 p-4">
            <img src={Flybee} alt="" id="client-logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;