import React from "react";
import MetaTags from "../common/Meta";
import Dws1 from "../../assets/images/dws1.png";
import Dws2 from "../../assets/images/dws2.png";
import gplay from "../../assets/images/play.png";
import AppStoreImg from "../../assets/images/app-store.png";

const Drivewithus = () => {
  return (
       <div>
       <MetaTags
        title="Drive with us। Car Ads"
        description="Car Ads is an OOH Ad-Tech advertising technology start-up in Bangladesh that connects brands, drivers & consumers to create measurable outdoor advertising."
        keywords="Car advertising, Car ads, Car wrap advertising, Vehicle advertising, Digital advertising on cars, Car advertisements, Car branding, Ooh advertising, Out of home advertising, ooh ads, Ooh billboard, Ooh agency, Ooh companies, Car advertising agency, Car advertising companies."
        />
        <div className="container">
           <div className="col-lg-12 col-md-12 col-sm-12 col-12 brands" style={{ marginTop: 30 }}>
              <h1>
                <span>Drive with us</span>
              </h1>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 drive-with-us-context">
                      <p>
                        People Are Wrapping Their Cars in Ads to Make Extra Cash.<br />
                        Car Ads largest car wrap advertising company in Bangladesh. we have been helping thousands of drivers earn extra money, each month, for something they already do With a legitimate car wrap, you can expect to earn roughly TK4000 to TK6000 per month depending on how much you drive and how big of an ad you put….
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                       <img src={Dws1} id="dws1" />
                    </div>
                </div>
            </div>
            <br/><br/>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <img  id="dws2" src={Dws2} width="470px" height="250px"/>
            </div><br/><br/>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 drive-with-us-context">
               <p>
                How Much Can You Earn When You Choose Vehicle Wrap Advertising?<br/>
                Did you know that you can make good money in advertising? Car wrap ads are no different! You can make up to $400 per month driving with an ad on your car.

                <br/><br/>

                Drivers. We pay you, to place removable ads on your vehicle. That's it, drive as you normally do and earn money doing it! ... Get paid to drive……
                <br/>Transform your car into a source of extra income and earn money for the kilometers you are already driving every day in your city…..
               </p>
                <br/><br/><br/><br/><br/>
            </div>
              <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="row">
                      <div className="col-lg-6 offset-lg-1 col-md-7 col-sm-10 col-12">
                      <br/>
                         <h1 className="dws-heading">Download Our App</h1><br/>
                      </div>
                      <div className="col-lg-5 col-md-5 col-sm-6 col-12">
                         <div className="d-flex mt-4 os-logo">
                            <a
                              href="https://play.google.com/store/apps/details?id=com.carads.driver"
                              target="_blank"
                              className=""
                              rel="noreferrer"
                            >
                              <img src={gplay} className="playstore" alt="Google Play" style={{ maxWidth: 180 }} />
                            </a>
                            <a href="#" type="button">
                              <img
                                src={AppStoreImg}
                                alt="App store"
                                style={{ maxWidth: 180 }}
                                className="dwsapple"
                              />
                            </a>
                          </div>
                      <br />
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
  	);
  };

export default Drivewithus;