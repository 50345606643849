import React from "react";
import "./AboutUs.css";
import MetaTags from "../common/Meta";
import fronthalf from "../../assets/images/About-us2.jpg";

const AboutUs = () => {
  return (
    <div>
      <MetaTags
        title="About us | Car Ads"
        description="Car Ads is an OOH Ad-Tech advertising startup in Bangladesh that connects brands, drivers & consumers to create measurable outdoor and digital advertising."
        keywords="Car advertising, Car ads, Car wrap advertising, Vehicle advertising, Digital advertising on cars, Car advertisements, Car branding, Ooh advertising, Out of home advertising, ooh ads, Ooh billboard, Ooh agency, Ooh companies, Car advertising agency, Car advertising companies"
      />
        <div className="aboutus-title">
              <h1>ABOUT US</h1>
        </div>
        <div className="container">
           <div className="aboutus-content">
           <br/><br/>
                <p>
                Car Ads is an OOH Ad-Tech advertising technology startup in Bangladesh that connects brands, drivers &amp; consumers to create
                  measurable outdoor and digital advertising. One of the most affordable cost per impression for almost any
                  other outdoor advertisement.
                </p>
                <p>
                  Make your brand visible through reality.<br/>
                  We offer an app that drivers and consumers can download to select brand stickers they’d like placed on their cars.
                </p>
                <br />
                <h2>WHY CHOOSE US?</h2>
                <p>We are specialists in Car wrap Advertising &amp; Branding.<br />
                    > Strong and respected brand<br />
                    > Experience<br />
                    > Innovations<br />
                    > Professional approach<br />
                    > Sharing practical experience<br />
                    > Partnership with Manufacturers
                </p>
                <h2>Why we do, What we do</h2>
                <p>
                Car Ads matches <a href="/driver" target="_blank">drivers</a> with <a href="/brands" target="_blank">advertisers</a>.<br/>
                The team at Car Ads will help you launch effective, head-turning campaigns with bold car advertising.<br/>
                <br/>
                Our main focus is customer&#39; satisfaction. The result is a seamless service with top-quality materials and outstanding customer service. Every project should be a source of pride for the client and satisfaction from the outcome and the satisfaction of accomplishment.<br/>
                The delight of our customers is our primary priority. Our final product combines excellent service, high-quality materials, and excellent customer service. Every day, our branches create one-of-a-kind works of art, and we&#39;re proud to be a part of it.
                </p>
                <h2>OUR MISSION</h2>
                <p>We can wrap your car with quality wrap foils or protect your car with our paint protection film. We can create a unique design for your car within three days! <br/>
                  We are the Car Ads vehicle advertising company and we guarantee professional quality of car sticker design!</p>

           </div>
        </div>
        <img src={fronthalf} alt="" id="aboutus-image" />
    </div>
  );
};

export default AboutUs;
