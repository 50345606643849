import React from "react";
import ebangla from "../../assets/images/ebanglamart.png";
import MetaTags from "../common/Meta";

const Blogs = () => {
  return (
    <div>
    <MetaTags
        title="Blogs | Car Ads"
        description="car wrap advertising, ooh advertising, cheap outdoor ads, best ooh advertising. Get your brand moving in the real world with a vehicle wrap from us!"
        keywords="Car advertising, Car ads, Car wrap advertising, Vehicle advertising, Digital advertising on cars, Car advertisements, Car branding, Ooh advertising, Out of home advertising, ooh ads, Ooh billboard, Ooh agency, Ooh companies, Car advertising agency, Car advertising companies."
      />
      <div className="container">
        <div className="col-md-6 brands" style={{ marginTop: 30 }}>
          <h1>
            <span>Our Blogs</span>
          </h1>
        </div>
      </div>
    </div>  
  );
};

export default Blogs;