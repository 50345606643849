import React from "react";
import { Helmet } from "react-helmet";

class MetaTags extends React.Component {
  render() {
    const { title, description, keywords } = this.props;
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="title" content={title} />
        <meta name="keywords" content={keywords} />
        <link rel="canonical" href="https://carads.com.bd" />
      </Helmet>
    );
  }
}

export default MetaTags;
