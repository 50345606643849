import React from "react";
import MetaTags from "../common/Meta";
import Dws1 from "../../assets/images/dws1.png";
import Dws2 from "../../assets/images/dws2.png";
import gplay from "../../assets/images/play.png";
import AppStoreImg from "../../assets/images/app-store.png";

const Adwithus = () => {
  return (
       <div>
       <MetaTags
        title="Advertise with us। Car Ads"
        description="Car Ads is an OOH Ad-Tech advertising technology start-up in Bangladesh that connects brands, drivers & consumers to create measurable outdoor advertising."
        keywords="Car advertising, Car ads, Car wrap advertising, Vehicle advertising, Digital advertising on cars, Car advertisements, Car branding, Ooh advertising, Out of home advertising, ooh ads, Ooh billboard, Ooh agency, Ooh companies, Car advertising agency, Car advertising companies."
        />
        <div className="container">
           <div className="col-lg-12 col-md-12 col-sm-12 col-12 brands" style={{ marginTop: 30 }}>
              <h1>
                <span>Advertise with us</span>
              </h1>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 drive-with-us-context">
                <p>
                  Vehicle wraps are a unique and creative way to advertise your brand. The wide variety of colors allows you to create an eye-catching design that will differentiate your company and create awareness for your brand, while the durability of the vinyl allows your designs to last several years.<br/><br/>
                  While there are many advantages to vehicle wraps, here are the top 10 advantages for using wraps as an advertising medium.
                </p><br/><br/>
                <h5>Vehicle Wraps Grab Attention</h5><br/>
                <h5>Wraps are Great for Local Businesses</h5><br/>
                <h5>Vehicle Wraps Create Exposure</h5><br/>
                <h5>Strategic and creative versatility</h5><br/>
                <h5>Brand consistency</h5><br/>
                <h5>Right Tactic, Right Time</h5><br/>
                <h5>Car wrap conveys eye-catching messages</h5><br/>
                <h5>An Impression On Larger Amount Of Audience</h5><br/>
                <h5>An Investment On Advertisement That Will Last Long</h5><br/>
                <h5>Your Branding Isn’t Confined To A Single Location</h5><br/>
            </div>
            <br/><br/>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <img  id="dws2" src={Dws2} width="470px" height="250px"/>
            </div><br/><br/>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 drive-with-us-context">
                <h1>Less In Cost More In Profit</h1>
                <br/>
               <p>
                Vehicle graphic is one of the great aspects of marketing that makes a huge benefit. <br/>
                we suggest you to do car advertising as it’s a great way to spread your marketing message all over the city. It is one of the most effective forms of advertising.
               </p>
                <br/><br/>

                <h1>Lends Trust and Credibility</h1>
                <br/>
               <p>
                You may be a small business owning a single vehicle. But the potential customers attach a higher brand value the moment they view your wrapped vehicle.<br/>
                In order to be effective, brand identity should help you to build a community of customers that are loyal and trust what you are selling.
               </p>
                <br/><br/>

                <h1>Why Choose us</h1>
                <br/>
               <p>
                Car Ads is an OOH Ad-Tech advertising technology startup in Bangladesh that connects brands, drivers & consumers to create measurable outdoor and digital advertising. <br/><br/>
                We offer an app that drivers and consumers can download to select brand stickers they’d like placed on their cars.
               </p>
                <br/><br/>
            </div>
              <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="row">
                      <div className="col-lg-6 offset-lg-1 col-md-7 col-sm-10 col-12">
                      <br/>
                         <h1 className="dws-heading">Download Our App</h1><br/>
                      </div>
                      <div className="col-lg-5 col-md-5 col-sm-6 col-12">
                         <div className="d-flex mt-4 os-logo">
                            <a
                              href="https://play.google.com/store/apps/details?id=com.carads.brand" target="_blank" className="mr-2" rel="noreferrer">
                              <img src={gplay} className="playstore" alt="Google Play" style={{ maxWidth: 180 }} />
                            </a>
                            <a href="#" type="button">
                              <img
                                src={AppStoreImg}
                                alt="App store"
                                style={{ maxWidth: 180 }}
                                className="dwsapple"
                              />
                            </a>
                          </div>
                      <br />
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
    );
  };

export default Adwithus;